import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";
import {Container, Box, Divider} from "@mui/material";
import AppHeaderElement from "../../style/app_header";

const mdpt1 = `
By trade I am a software engineer, but this was not always the case; I have a long history as a musician. I've played
many instruments over the years, some to very high levels of proficiency and academic recognition, but the one
instrument to which I always return is the drums.

There is not terribly much that I have to say about the art and discipline of performance that hasn't already been
explored by far more qualified authors, but as a student of music technology, I do tend to explore details others may
take for granted. This generally involves at least one deep dive into the details of building various different
instruments, and can sometimes extend to trying my hand at building them myself.

For drums, I found the general availability of information on the subject to be underwhelming. Synthesizers, guitars,
pedals, and amplifiers are much more popular builds for hobbyists, and have spawned entire industries around communities
interested in such things. This lowers the barrier to entry for new hobbyists, wisdom doesn't decay as rapidly, and the
cycle feeds itself.

This is not to say that there are no blogs, communities, or resources available to anyone curious about drum technology,
but they can be a little more vague, and a lot harder to find. There is some inertia to overcome. My aim in writing
articles about drum tech is not to single-handedly rectify this, but rather to put to print what I've learned, add to
the list of hard-to-find and vague blogs, and hope that someone finds it useful.
`

const project_list = `
* [Plywood drum building](/drums/building): Guides and general wisdom
* [Drum ply calculator](/drums/calculator): Tool to help measure the length of each layer in a plywood drum
`

export default function DrumsProjectHome(): ReactElement {
    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', marginTop: "10px"}}>
            <AppHeaderElement title={"Drums"}/>
            <div style={{textAlign: "left", paddingBottom: "1px"}}>
                <ReactMarkdown children={mdpt1}/>
                <ReactMarkdown children={project_list}/>
            </div>
        </Container>
    )
}