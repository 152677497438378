import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";
import {Container, Box, Divider} from "@mui/material";
import AppHeaderElement from "../../style/app_header";

const mdpt1 = ``


export default function HowToBuildDrums(): ReactElement {
    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', marginTop: "10px"}}>
            <AppHeaderElement title={"Drum Building"}/>
            <div style={{textAlign: "left", paddingBottom: "1px"}}>
                <ReactMarkdown children={mdpt1}/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/workspace_overview.jpg"} alt="workspace overview" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/taped_veneer.jpg"} alt="taping a vertical layer" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/vacuum_press.jpg"} alt="pressing a layer" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/roller_jig.jpg"} alt="trimming the shell" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/squaring_edges.jpg"} alt="squaring the edges" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/finishing_pt1.jpg"} alt="finishing pt. 1" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/finishing_pt2.jpg"} alt="finishing pt. 2" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/marked_for_drilling.jpg"} alt="marked up for drilling hardware" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/bearing_edge.jpg"} alt="cutting the bearing edge" width={"100%"}/>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={"/drum/complete_shell.jpg"} alt="completed shell" width={"100%"}/>
                </Box>
            </div>
        </Container>
    )
}