import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";
import {Container, Box, Divider} from "@mui/material";
import AppHeaderElement from "../../style/app_header";

const mdpt1 = `
## About:
The Metaphase MP-1 Percussion Synthesizer is an upcoming VST plug-in for Mac, PC and Linux, which pays homage to the
venerable Korg ER-1.

The MP-1 reproduces the controls, voice architecture, and design language of the original in faithful detail, while 
expanding on the voice count, routing options, and depth of control.

Since this is a plug-in, it does away with the original's sequencer and Motion Seq controls, instead exposing MIDI
routing and parameter automation. It even has the ability to route incoming note data from a MIDI channel to a single
voice, allowing individual percussion instruments to play melodies and bass lines.

**Look for the MP-1 to be available for purchase in 2025 on 
[Metaphase's itch.io page](https://metaphase-audio.itch.io/).**

## Example Audio:`


export default function MP1ProjectHome(): ReactElement {
    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', marginTop: "10px"}}>
            <AppHeaderElement title={"MP-1 Percussion Synthesizer"}/>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <img
                    src="/mp1/screenshot.png"
                    alt="MP-1"
                    width={"90%"}
                />
            </Box>
            <div style={{textAlign: "left", paddingBottom: "1px"}}>
                <ReactMarkdown children={mdpt1}/>
                <iframe width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1904030671&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                <div >
                    <a href="https://soundcloud.com/sound_design-938713285" title="Sound_design" target="_blank" >Sound_design</a> ·
                    <a href="https://soundcloud.com/sound_design-938713285/sets/mp-1-sample-sounds" title="MP-1 Sample Sounds" target="_blank">MP-1 Sample Sounds</a>
                </div>
            </div>
        </Container>
    )
}