import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";
import {Container, Box, Divider} from "@mui/material";
import AppHeaderElement from "../../style/app_header";

const mdpt1 = `
The playdate is a really neat little games system. With an ARM Cortext M7, a 1-bit display, and controls that were state
of the art in 1989, it's specs don't exactly jump off the page at you. It does have a few extra tricks up its sleeves to
make it more than a simply a miniature (albiet high-resolution) Game Boy, but it's undeniably limited in what it can
do.`

const mdpt2 = `
This would appear to be by design. The number of ways the sentiment "limitations breed creativity" has been phrased over
the years is countless, and it's clearly one that the folks at Teenage Engineering and Panic! believe in wholeheartedly.
 
I have found the Playdate to be downright inspiring, and have put together a growing number of projects with the device
that are either catalogued here, or will be soon, so stay tuned.
`

const project_list = `
* [Playdate CPP and Extensions](/playdate/cpp): Use C++ for your Playdate projects!
* [Physynth](/playdate/physynth): Make music from a 2D physics simulation
`


export default function PlaydateProjectHome(): ReactElement {
    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', marginTop: "10px"}}>
            <AppHeaderElement title={"Playdate Projects"}/>
            <div style={{textAlign: "left", paddingBottom: "1px"}}>
                <ReactMarkdown children={mdpt1}/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/1/17/Playdate_front-view.png"
                        alt="The Playdate"
                        width="300"
                    />
                </Box>
                <ReactMarkdown children={mdpt2}/>
                <Divider sx={{ paddingTop: "10px" }} />
                <ReactMarkdown children={project_list}/>
            </div>
        </Container>
    )
}