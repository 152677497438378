import React, {ReactElement} from "react";
import {Routes, Route} from "react-router-dom";
import Home from "../components/home";
import {FitbitAuthCallback, FitbitAuthorize} from "../components/fitbit_leaderboard/auth";
import {FitbitActivityLeaderboard} from "../components/fitbit_leaderboard/leaderboard";
import LightDesignerApp from "../components/light_designer/main_interface";
import PlaydateProjectHome from "../components/playdate/main";
import PlaydateCPP from "../components/playdate/cpp_ext";
import DrumsProjectHome from "../components/drums/main";
import DrumPlyCalculatorApp from "../components/drums/calculator";
import HowToBuildDrums from "../components/drums/how_to_build_drums";
import Physynth from "../components/playdate/physynth";
import MP1ProjectHome from "../components/mp1/main";


export default function URLRouter(): ReactElement {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/fitbit/auth" element={<FitbitAuthorize/>}/>
            <Route path="/fitbit/auth_callback" element={<FitbitAuthCallback/>}/>
            <Route path="/drums" element={<DrumsProjectHome/>}/>
            <Route path="/drums/calculator" element={<DrumPlyCalculatorApp/>}/>
            <Route path="/drums/building" element={<HowToBuildDrums/>}/>
            <Route path="/fitbit/leaderboard" element={<FitbitActivityLeaderboard/>}/>
            <Route path="/light_designer" element={<LightDesignerApp/>}/>
            <Route path="/metaphase/MP-1" element={<MP1ProjectHome/>}/>
            <Route path="/playdate" element={<PlaydateProjectHome/>}/>
            <Route path="/playdate/cpp" element={<PlaydateCPP/>}/>
            <Route path="/playdate/physynth" element={<Physynth/>}/>
        </Routes>
    );
}