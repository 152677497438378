import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";
import {Container, Box} from "@mui/material";
import AppHeaderElement from "../../style/app_header";

const mdpt1 = `
Physynth is an instrument for the Playdate for the organic generation of sound, inspired by the cult classic "game"
Electroplankton for the Nintendo DS. Players control all aspects of 2 dimensional simulation of a physical space, 
including gravity, obstacles, and more. As objects move through the world and collide, this information is 
converted into an equally flexible sonic representation. By controlling this world, generative music can be created.

**The instrument is in early release: [Download Physynth from itch.io](https://metaphase-audio.itch.io/physynth)**

## Overview
In Physynth, there are two types of physical objects, "Grains" and "Blocks." Grains are spawned into the world by
Generators, and spend their lives moving through the physical space. Players have control over generators, and can
manipulate properties such as how quickly they spawn grains, how forcefully, with how wide a spread, etc. 

Blocks, are fixed bodies created by the player. They can be moved, resized, rotated, and re-shaped, by the player at any
time, but do not do so independently. 

When struck by a Grain, Blocks transmit information about that collision to the sound engine, potentially triggering a
sound.
`;

const mdPt2 = `
Important properties of the collision include, but are not limited to, grain size, grain velocity, location of 
the collision within the world, color of the grain, and more.

The player has full control over the sound engine, and can route collision information into each of its parameters.

Likewise, the player has control over the general properties of the physical world, allowing control over gravity, how
many grains are allowed to exist at a time, and whether or not grains can escape the visible play area.
`;

const mdPt3 = `
This packs a lot of power into a device with limited controls, but clever use of the crank makes navigation and world
creation a breeze. With some careful planning, players can construct playable worlds, and perform with them.

## Early Access
This instrument, while fully functional, and a lot of fun, has a few features that will be implemented before it is
consider it "complete".

### Performance
The number of ways one can use this instrument in performance will be expanded to include:
* Crank-based grain generation (think like a Barrel Organ)
* Scene sequencing and transitions
* Bulk quantization and key changing

### Sound Upgrades
The current sound engine is great, but unless that's the sound your looking for, it is currently limiting. It was always
a goal of the instrument to give even more options to the player with regards to the sound engine itself:
* The number of sound engines will be expanded, including percussive-focused engines
* Multiple engines will be able to be used at once
    * Blocks will be able to route their information to one or more engines.
* Sound effects! Delay, Reverb, Chorus, etc. with parameters exposed to collision information

### More Presets
There are precious few presets. The creation of more as a way of helping players escape "the fear of the blank page" is
a critical step on any electronic instrument's maturity. This may also involve releasing tools which enable people to
build scenes on their computer or in a web browser.

### UI/UX tweaks
Adding new features is all well and good. However, the more you can do with this instrument, the harder it will become
to manipulate "just so." Any synthesizer fan will tell you that menu diving, while effective in improving access to the
instrument's power, also interrupts the creative process.

A point will likely come where there is going to need to be more thought put into simplifying access, or perhaps
re-working whole paradigms for world construction and performance, in order to free up mental load. How that looks right
now is not clear, and will depend heavily on feedback from early-access users.
`;

const part2 = `

`;

export default function Physynth(): ReactElement {
    return (
        <Container maxWidth="md" style={{backgroundColor: 'white', marginTop: "10px"}}>
            <AppHeaderElement title={"Physynth"}/>
            <div style={{textAlign: "left", paddingBottom: "1px"}}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img
                        src={"/physynth/card.png"}
                        alt="Physynth"
                        width={250}
                    />
                </Box>
                <ReactMarkdown children={mdpt1}/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img
                        src={"/physynth/action_shot.gif"}
                        alt="in action"
                    />
                </Box>
                <ReactMarkdown children={mdPt2}/>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img
                        src={"/physynth/edit_synth.gif"}
                        alt="editing the synth"
                    />
                </Box>
                <ReactMarkdown children={mdPt3}/>
            </div>
        </Container>
    )
}